<template>
  <div style="margin-top: -1px" :class="containerClass">
    <button
      type="button"
      :class="`${headerClass} pl-4 w-full py-4 group text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none`"
      @click="toggle"
    >
      <slot name="label" />
      <span
        class="h-4 w-4 transition ease-in-out duration-200 transform"
        :class="visible ? 'rotate-90' : 'rotate-0'"
      >
        <slot name="icon" />
      </span>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-150"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="visible">
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['noBorder', 'containerClass'],
  data: () => ({
    visible: false,
  }),
  computed: {
    headerClass() {
      return !this.noBorder && 'border-t border-b border-lovely-lavender pl-4';
    },
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
  },
};
</script>
