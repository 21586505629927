<!-- eslint-disable vue/singleline-html-element-content-newline vue/html-self-closing -->
<template>
  <div>
    <div v-if="error" class="p-6">Error loading social feed</div>
    <ul v-if="!error" class="list-none">
      <carousel :per-page="3" :navigation-enabled="true" :pagination-enabled="false">
        <slide v-for="(post, i) in posts" :key="i">
          <li class="mx-2 h-full max-h-40">
            <div class="relative rounded-lg overflow-hidden h-full">
              <a class="h-full" target="_blank" rel="noopener noreferrer" :href="post.url">
                <img class="w-full h-full object-cover" :src="post.imageUrl" sizes="100vw" />
              </a>
              <div
                class="absolute w-full flex justify-between items-baseline bottom-0 right-0 left-0 px-3 py-1.5 text-white"
              >
                <div class="grid grid-flow-col gap-4">
                  <div v-if="post.likes > 0" class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 15.118 13.341"
                      class="w-3 h-3 mr-1"
                    >
                      <path
                        d="M14 1.2a4 4 0 00-5.9 0l-.5.6-.6-.6A4 4 0 001.2 7l6.4 6.3L13.9 7a4 4 0 000-5.8"
                        fill="currentColor"
                        fill-rule="evenodd"
                      />
                    </svg>
                    <span class="text-xs font-frank font-bold text-white">{{ post.likes }}</span>
                  </div>
                  <div v-if="post.comments > 0" class="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 15.118 15.118"
                      class="w-2.5 h-2.5 mr-1 text-white"
                    >
                      <path
                        d="M4.7 7.6a.9.9 0 001 .9h3.7a1 1 0 001-1 .9.9 0 00-1-.9H5.7a1 1 0 00-1 1zM0 4.7A4.7 4.7 0 014.7 0h5.7A4.7 4.7 0 0115 4.7v9.5a.9.9 0 01-1 1H4.8A4.7 4.7 0 010 10.3z"
                        fill="currentColor"
                        fill-rule="evenodd"
                      />
                    </svg>
                    <span class="text-xs font-frank font-bold text-white">{{ post.comments }}</span>
                  </div>
                </div>
                <div>
                  <svg
                    v-if="post.source === 'Instagram'"
                    class="w-5 h-5 mr-1"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M12.004 5.838c-3.403 0-6.158 2.758-6.158 6.158 0 3.403 2.758 6.158 6.158 6.158 3.403 0 6.158-2.758 6.158-6.158 0-3.403-2.758-6.158-6.158-6.158zm0 10.155c-2.209 0-3.997-1.789-3.997-3.997s1.789-3.997 3.997-3.997 3.997 1.789 3.997 3.997c.001 2.208-1.788 3.997-3.997 3.997z"
                    />
                    <path
                      fill="currentColor"
                      d="M16.948.076c-2.208-.103-7.677-.098-9.887 0-1.942.091-3.655.56-5.036 1.941C-.283 4.325.012 7.435.012 11.996c0 4.668-.26 7.706 2.013 9.979 2.317 2.316 5.472 2.013 9.979 2.013 4.624 0 6.22.003 7.855-.63 2.223-.863 3.901-2.85 4.065-6.419.104-2.209.098-7.677 0-9.887-.198-4.213-2.459-6.768-6.976-6.976zm3.495 20.372c-1.513 1.513-3.612 1.378-8.468 1.378-5 0-7.005.074-8.468-1.393-1.685-1.677-1.38-4.37-1.38-8.453 0-5.525-.567-9.504 4.978-9.788 1.274-.045 1.649-.06 4.856-.06l.045.03c5.329 0 9.51-.558 9.761 4.986.057 1.265.07 1.645.07 4.847-.001 4.942.093 6.959-1.394 8.453z"
                    />
                    <circle fill="currentColor" cx="18.406" cy="5.595" r="1.439" />
                  </svg>

                  <svg
                    v-if="post.source === 'Facebook'"
                    class="w-5 h-5 mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16.471 31.541"
                  >
                    <path
                      d="M10.7 31.5V17.2h4.9l.7-5.6h-5.6V8c0-1.6.5-2.8 2.8-2.8h3V.2L12 0C7.9 0 4.9 2.6 4.9 7.4v4.2H0v5.6h5v14.3z"
                      fill="currentColor"
                      fill-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </li>
        </slide>
      </carousel>
    </ul>
  </div>
</template>

<script>
// eslint-disable-next-line
import axios from 'axios';

const postsFromJuicer = (data, filterTerm) => {
  return data.posts.items
    .filter((item) => (filterTerm ? item.source.term === filterTerm : true))
    .map((item) => ({
      url: item.full_url,
      imageUrl: item.image,
      source: item.source.source,
      likes: item.like_count || 0,
      comments: item.comment_count || 0,
    }));
};

export default {
  props: {
    feedUrl: {
      type: String,
      default: '',
    },
    filterTerm: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    posts: null,
    error: null,
  }),
  mounted() {
    axios
      .get(this.feedUrl)
      .then((response) => (this.posts = postsFromJuicer(response.data, this.filterTerm)))
      .catch((e) => {
        console.log(e);
        this.error = true;
      });
  },
};
</script>

<style>
@media (max-width: 768px) {
  .VueCarousel-navigation {
    display: none;
  }
}
</style>
