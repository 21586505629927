<template>
  <div class="relative">
    <button
      type="button"
      :class="`group text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none border-b-2 ${
        visible ? focusClass : 'border-transparent'
      }`"
      @click="toggle"
    >
      <slot name="label" />
      <svg
        class="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <div v-show="visible" class="absolute z-100 mt-3 transform px-2 sm:px-0 lg:ml-0">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div :class="`relative grid bg-white px-5 py-6 sm:gap-8 sm:p-8 ${dropdownClass}`">
            <slot name="content" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    dropdownClass: {
      type: String,
      default: '',
    },
    focusClass: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    visible: false,
  }),
  created() {
    document.addEventListener('click', this.documentClick);
  },
  unmounted() {
    document.removeEventListener('click', this.documentClick);
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
    documentClick(e) {
      if (!this.$el.contains(e.target)) {
        this.visible = false;
      }
    },
  },
};
</script>
