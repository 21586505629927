<template>
  <v-calendar :attributes="attrs" />
</template>

<script>
export default {
  delimiters: ['${', '}'],
  props: {
    events: {
      type: String,
      default: '',
    },
  },
  data() {
    const events = JSON.parse(this.events);
    return {
      attrs: events.map((event) => ({
        dates: new Date(event.dates),
        dot: {
          style: {
            'background-color': event.color,
          },
        },
        popover: {
          label: event.label,
          visibility: 'focus',
        },
        customData: event,
      })),
    };
  },
};
</script>
