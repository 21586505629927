/* global google */

function disableRelatedFields(form) {
  // Disable the fields that are going to be populated by autocomplete
  const relatedFieldNames = ['suburb', 'city', 'state', 'zipCode', 'postcode', 'country'];
  relatedFieldNames.forEach((fieldName) => {
    const fieldElement = form.querySelector(`[name='${fieldName}']`);
    if (fieldElement) fieldElement.disabled = true;
  });
}

function fillMappingField(form, fieldName, fieldValue, disabled = true) {
  const fieldElement = form.querySelector(`[name='${fieldName}']`);
  if (fieldElement) {
    fieldElement.value = fieldValue;
    fieldElement.disabled = disabled;
  }
}

function fillInAddress(autocomplete, form, autocompleteField) {
  // Get the place details from the autocomplete object.
  const place = autocomplete.getPlace();
  // console.log(place);

  const address = { address1: '', suburb: '', state: '', postcode: '', country: '' };

  place.address_components.forEach((component) => {
    const [componentType] = component.types;

    switch (componentType) {
      case 'street_number':
        address.address1 = `${component.long_name} ${address.address1}`;
        break;

      case 'route':
        address.address1 += component.long_name;
        break;

      case 'postal_code':
        address.postcode = `${component.long_name}${address.postcode}`;
        break;

      case 'postal_code_suffix':
        address.postcode = `${address.postcode}-${component.long_name}`;
        break;

      case 'locality':
        address.suburb = component.long_name;
        break;

      case 'administrative_area_level_1':
        address.state = component.short_name;
        break;

      case 'country':
        address.country = component.long_name;
        break;

      default:
        break;
    }
  });

  // reset the address autocomplete field
  autocompleteField.value = address.address1;

  // try to find the mapping firlds and fill values
  fillMappingField(form, 'address', address.address1);
  fillMappingField(form, 'suburb', address.suburb);
  fillMappingField(form, 'city', address.suburb);
  fillMappingField(form, 'state', address.state);
  fillMappingField(form, 'zipCode', address.postcode);
  fillMappingField(form, 'postcode', address.postcode);
  fillMappingField(form, 'country', address.country);
}

export default function init(vue) {
  const elements = vue.$el.querySelectorAll('form[data-freeform] .addressAutoComplete');

  if (!elements) {
    console.log('no autocomplete found');
    return;
  }

  elements.forEach((element) => {
    const form = element.closest('form[data-freeform]');
    // disable the fields that autocomplete will populate.
    disableRelatedFields(form);
    // make sure they are "re-disabled" on ajax submit
    form.addEventListener('freeform-ajax-after-submit', () => {
      disableRelatedFields(form);
    });
    // make sure they are "re-enabled" on regular submit
    form.addEventListener('submit', () => {
      [...form.querySelectorAll(':disabled')].forEach((el) => (el.disabled = false));
    });

    // initialize each autocomplete field
    const autocomplete = new google.maps.places.Autocomplete(element, {
      types: ['address'],
      componentRestrictions: { country: ['au'] },
      fields: ['address_components'],
    });

    // fill in other fields when address is updated
    autocomplete.addListener('place_changed', () => {
      return fillInAddress(autocomplete, form, element);
    });
  });
}
