import '@/styles/main.scss';
import '@/js/modules/freeform';
import Calendar from '@/vue/Calendar.vue';
import Collapse from '@/vue/Collapse.vue';
import DropDown from '@/vue/DropDown.vue';
import SlickCarousel from '@/vue/SlickCarousel.vue';
import Modal from '@/vue/Modal.vue';
import SidePanel from '@/vue/SidePanel.vue';
import SocialFeed from '@/vue/SocialFeed.vue';
import initAddressAutocomplete from '@/js/modules/address-autocomplete';
import { lockBodyScroll, unlockBodyScroll } from '@/js/utils/lock-body-scroll';

document.documentElement.classList.remove('no-js');

const main = async () => {
  if (document.querySelector('#app')) {
    // import vue instance
    const { default: Vue } = await import('vue');
    // import required modules
    const { default: VueCarousel } = await import('vue-carousel');
    const { default: vmodal } = await import('vue-js-modal');
    const { default: VCalendar } = await import('v-calendar');

    Vue.use(VueCarousel);
    Vue.use(vmodal);
    Vue.use(VCalendar);
    // Vue.use('vueslickcarousel', VueSlickCarousel);

    Vue.config.productionTip = false;

    // eslint-disable-next-line no-new
    new Vue({
      el: '#app',
      delimiters: ['${', '}'],
      components: {
        Calendar,
        Collapse,
        DropDown,
        Modal,
        SidePanel,
        SocialFeed,
        slickcarousel: SlickCarousel,
      },
      data() {
        return {
          menuOpen: false,
        };
      },
      mounted() {
        initAddressAutocomplete(this);
        unlockBodyScroll();
      },
      methods: {
        toggleMenu() {
          this.menuOpen = !this.menuOpen;

          if (this.menuOpen) lockBodyScroll();
          if (!this.menuOpen) unlockBodyScroll();
        },
      },
    });
  }
};

main()
  .then(() => {})
  .catch((err) => {
    console.error(err);
  });

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => console.log('HMR'));
}
