function customMessage(event, classBanner, bannerMessage) {
  event.preventDefault();
  const { options } = event.freeform;
  const { form } = event;
  const classNames = options[classBanner]; // Get the error or success banner css class names
  // create a custom banner <div>
  const banner = document.createElement('div');
  banner.classList.add(...classNames.split(' '));
  banner.innerHTML = `<div class="px-4 py-3 relative text-base" role="alert"><strong class="font-bold">${options[bannerMessage]}</strong></div>`;
  form.appendChild(banner);
}

window.addEventListener('DOMContentLoaded', () => {
  function removeErrorMessage(event) {
    const { form } = event;
    // Find every element with the class "error"
    const allErrorElements = form.querySelectorAll('.ff-form-errors, .ff-form-success');
    allErrorElements.forEach((element) => {
      // Remove element from the DOM
      element.remove();
    });
  }

  const freeForms = [...document.querySelectorAll('.freeform')];
  if (freeForms.length === 0) return;

  freeForms.forEach((freeForm) => {
    freeForm.addEventListener('freeform-ready', (event) => {
      event.options.errorClassBanner =
        'ff-form-errors bg-red-100 border border-red-400 rounded text-red-700';
      event.options.successClassBanner =
        'ff-form-success bg-green-100 border border-green-900 rounded text-green-900';
    });

    freeForm.addEventListener('freeform-render-success', (event) => {
      customMessage(event, 'successClassBanner', 'successBannerMessage');
      // Remove success message after 5 seconds
      setTimeout(() => {
        removeErrorMessage(event);
      }, 5000);
    });

    freeForm.addEventListener('freeform-render-form-errors', (event) => {
      customMessage(event, 'errorClassBanner', 'errorBannerMessage');
    });

    freeForm.addEventListener('freeform-remove-messages', (event) => {
      removeErrorMessage(event);
    });

    freeForm.addEventListener('freeform-on-submit', (event) => {
      removeErrorMessage(event);
    });
  });
});
