const lockedClass = 'js-lock-body-scroll';
const bodyEl = document.body;

export function lockBodyScroll() {
  bodyEl.classList.add(lockedClass);
}

export function unlockBodyScroll() {
  bodyEl.classList.remove(lockedClass);
}
