<template>
  <VueSlickCarousel v-bind="templateSettings">
    <slot name="slide" />
  </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';

export default {
  components: {
    VueSlickCarousel,
  },
  props: {
    settings: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      templateSettings: JSON.parse(this.settings),
    };
  },
};
</script>
