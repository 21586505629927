<template>
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div class="absolute max-h-screen flex overflow-hidden bg-gray-100">
    <div class="fixed z-10 top-0 bottom-0 left-0 h-6 w-2" />
    <div class="md:hidden max-h-screen">
      <div class="fixed inset-0 flex z-40">
        <transition
          enter-active-class="transition-opacity ease-linear duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition-opacity ease-linear duration-300"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div v-show="open" class="fixed inset-0">
            <div class="absolute inset-0 bg-gray-600 opacity-75" />
          </div>
        </transition>

        <transition
          enter-active-class="transition ease-in-out duration-300 transform"
          enter-class="-translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transition ease-in-out duration-300 transform"
          leave-class="translate-x-0"
          leave-to-class="-translate-x-full"
        >
          <div
            v-show="open"
            class="absolute flex-1 flex flex-col max-w-xs w-full bg-white h-screen"
          >
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button
                class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                @click="hide"
              >
                <span class="sr-only">Close sidebar</span>
                <svg
                  class="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="flex-1 h-0 pt-5 pb-4 max-h-screen">
              <slot name="content" />
            </div>
          </div>
        </transition>
        <div class="flex-shrink-0 w-14" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    panelId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    open: true,
  }),
  methods: {
    show() {
      this.open = true;
    },
    hide() {
      this.open = false;
    },
  },
};
</script>
