<template>
  <div>
    <button class="w-full" @click="show">
      <slot name="trigger" />
    </button>
    <modal :name="modalId" height="auto" :adaptive="true" :scrollable="true">
      <button class="absolute top-0 right-0 text-white p-4" @click="hide">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"
          />
        </svg>
      </button>
      <slot name="content" />
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    modalId: {
      type: String,
      default: '',
    },
  },
  methods: {
    show() {
      this.$modal.show(this.modalId);
    },
    hide() {
      this.$modal.hide(this.modalId);
    },
  },
};
</script>
